import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { loginbg } from "../../assets/images/base64/loginbg";
import { Button } from "@material-tailwind/react";
import OtpInput from "react-otp-input";
import { forgetPasswordApi, sendOtpApi } from "../../services/ApiServices";
import toast from "react-hot-toast";

const ForgetPassVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();

  var emailId = "";
  try {
    const { email_id } = location.state;
    emailId = email_id;
  } catch (e) {
    //id not found
  }
  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (!emailId) {
      navigate(`/`);
    }
  }, [emailId]);

  const handleSubmitOTP = (e) => {
    e.preventDefault();

    const params = {
      emailId: emailId,
      temppass: otp,
    };

    forgetPasswordApi(params)
      .then((res) => {
        const message = res.data.message;
        toast.success(message || "");
        navigate("/resetpassword", {
          state: {
            email_id: emailId,
          },
        });
        setOtp("");
      })
      .catch((err) => {
        const message = err.response.data.message;
        console.log("err >>>", err);
        toast.error(message || "Somthing went wrong!!");
      });
  };

  const handleResendotp = (e) => {
    const params = { emailId: emailId };
    sendOtpApi(params)
      .then((res) => {
        const message = res.data.message;
        toast.success(message || "OTP sended to your email address");
        setOtp("");
      })
      .catch((err) => {
        const message = err.response.data.message;
        console.log("err >>>", err);
        toast.error(message || "Somthing went wrong!!");
      });
  };

  return (
    <>
      <div className="grid grid-cols-12">
        <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 w-full 2xl:block xl:block lg:block hidden">
          <img
            src={loginbg}
            className="object-cover w-full h-screen"
            alt=""
          ></img>
        </div>
        <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 flex justify-center items-center">
          <div className="h-screen justify-center items-center flex">
            <div className="my-10 mx-5">
              <div className="my-2">
                <h1 className="text-3xl font-medium">Verification</h1>
              </div>
              <div className="my-4">
                <p className="text-sm font-normal text-[#7E7E7E]">
                  We have just sent a 4-digit verification code to your
                  <br />
                  registered email
                </p>
              </div>
              <div className="py-4">
                <form onSubmit={(e) => handleSubmitOTP(e)}>
                  <div className="flex justify-center items-center">
                    <OtpInput
                      className="w-full"
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      renderSeparator={<span> </span>}
                      renderInput={(props) => (
                        <input
                          {...props}
                          name="otp"
                          type="number"
                          className="otp m-2 p-3 text-black text-xl text-center focus:outline-none block w-10 h-10 rounded-full border-[1px] py-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#c2bebe]"
                          required
                        />
                      )}
                    />
                  </div>
                  <div className="my-6 text-center">
                    <Button
                      className="w-full text-sm py-3 rounded-lg capitalize font-medium bg-primary"
                      type="submit"
                    >
                      Verify{" "}
                    </Button>
                  </div>
                </form>
                <div className="my-6 text-center">
                  <h6
                    className="text-primary font-medium cursor-pointer"
                    onClick={(e) => handleResendotp()}
                  >
                    Resend Code
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassVerification;
