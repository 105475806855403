export const services = [
  {
    category: "furniture-assembly",
    items: [
      {
        service: "flatpack-assembly",
        imageSrc: "images/icons/furniture-assembly/flatpack-assembly.png",
        imageSrcMobile:
          "images/icons/furniture-assembly/flatpack-assembly-1.png",
        altText: "Flatpack Assembly Image",
      },
      {
        service: "furniture-assembly",
        imageSrc: "images/icons/furniture-assembly/furniture-assembly.png",
        imageSrcMobile:
          "images/icons/furniture-assembly/furniture-assembly-1.png",
        altText: "Furniture Assembly Image",
      },
      {
        service: "gym-equipment-assembly",
        imageSrc: "images/icons/furniture-assembly/gym-equipment-assembly.png",
        imageSrcMobile:
          "images/icons/furniture-assembly/gym-equipment-assembly-1.png",
        altText: "GYM Equipment Assembly Image",
      },
      {
        service: "furniture-disassembly",
        imageSrc: "images/icons/furniture-assembly/furniture-disassembly.png",
        imageSrcMobile:
          "images/icons/furniture-assembly/furniture-disassembly-1.png",
        altText: "Furniture Disassembly Image",
      },
      {
        service: "office-furniture-assembly",
        imageSrc:
          "images/icons/furniture-assembly/office-furniture-assembly.png",
        imageSrcMobile:
          "images/icons/furniture-assembly/office-furniture-assembly-1.png",
        altText: "Office Furniture Assembly Image",
      },
      {
        service: "shed-assembly",
        imageSrc: "images/icons/furniture-assembly/shed-assembly.png",
        imageSrcMobile: "images/icons/furniture-assembly/shed-assembly-1.png",
        altText: "Shed Assembly Image",
      },
      {
        service: "outdoor-furniture-assembly",
        imageSrc:
          "images/icons/furniture-assembly/outdoor-furniture-assembly.png",
        imageSrcMobile:
          "images/icons/furniture-assembly/outdoor-furniture-assembly-1.png",
        altText: "Outdoor Furniture Assembly Image",
      },
      {
        service: "something-else",
        imageSrc: "images/icons/something-else.png",
        imageSrcMobile: "images/icons/something-else-1.png",
        altText: "Something Else Image",
      },
    ],
  },
  {
    category: "handyman-services",
    items: [
      {
        service: "carpentry",
        imageSrc: "images/icons/handyman-services/carpentry.png",
        imageSrcMobile: "images/icons/handyman-services/carpentry-1.png",
        altText: "Carpentry Image",
      },
      {
        service: "curtains-and-blinds",
        imageSrc: "images/icons/handyman-services/curtains-and-blinds.png",
        imageSrcMobile:
          "images/icons/handyman-services/curtains-and-blinds-1.png",
        altText: "Curtains and Blinds Image",
      },
      {
        service: "kitchen-repair-and-install",
        imageSrc:
          "images/icons/handyman-services/kitchen-repair-and-install.png",
        imageSrcMobile:
          "images/icons/handyman-services/kitchen-repair-and-install-1.png",
        altText: "Kitchen Repair and Install Image",
      },
      {
        service: "plastering",
        imageSrc: "images/icons/handyman-services/plastering.png",
        imageSrcMobile: "images/icons/handyman-services/plastering-1.png",
        altText: "Plastering Image",
      },
      {
        service: "tiling",
        imageSrc: "images/icons/handyman-services/tiling.png",
        imageSrcMobile: "images/icons/handyman-services/tiling-1.png",
        altText: "Tiling Image",
      },
      {
        service: "tv-mounting",
        imageSrc: "images/icons/handyman-services/tv-mounting.png",
        imageSrcMobile: "images/icons/handyman-services/tv-mounting-1.png",
        altText: "TV Mounting Image",
      },
      {
        service: "fixtures-and-fittings",
        imageSrc: "images/icons/handyman-services/fixtures-and-fittings.png",
        imageSrcMobile:
          "images/icons/handyman-services/fixtures-and-fittings-1.png",
        altText: "Fixtures and Fittings Image",
      },
      {
        service: "flooring",
        imageSrc: "images/icons/handyman-services/flooring.png",
        imageSrcMobile: "images/icons/handyman-services/flooring-1.png",
        altText: "Flooring Image",
      },
      {
        service: "roof-and-guttering-repairs",
        imageSrc:
          "images/icons/handyman-services/roof-and-guttering-repairs.png",
        imageSrcMobile:
          "images/icons/handyman-services/roof-and-guttering-repairs-1.png",
        altText: "Roof and Guttering Repairs Image",
      },
      {
        service: "shelving",
        imageSrc: "images/icons/handyman-services/shelving.png",
        imageSrcMobile: "images/icons/handyman-services/shelving-1.png",
        altText: "Shelving Repairs Image",
      },
      {
        service: "windows-and-doors",
        imageSrc: "images/icons/handyman-services/windows-and-doors.png",
        imageSrcMobile:
          "images/icons/handyman-services/windows-and-doors-1.png",
        altText: "Windows and Doors Image",
      },
      {
        service: "something-else",
        imageSrc: "images/icons/something-else.png",
        imageSrcMobile: "images/icons/something-else-1.png",
        altText: "Something Else Image",
      },
    ],
  },
  {
    category: "electrical-services",
    items: [
      {
        service: "electrical-repairs",
        imageSrc: "images/icons/electrical-services/electrical-repairs.png",
        imageSrcMobile:
          "images/icons/electrical-services/electrical-repairs-1.png",
        altText: "Electrical Repairs Image",
      },
      {
        service: "testing-and-inspection",
        imageSrc: "images/icons/electrical-services/testing-and-inspection.png",
        imageSrcMobile:
          "images/icons/electrical-services/testing-and-inspection-1.png",
        altText: "Testing and Inspection Image",
      },
      {
        service: "CCTV-installation",
        imageSrc: "images/icons/electrical-services/CCTV-installation.png",
        imageSrcMobile:
          "images/icons/electrical-services/CCTV-installation-1.png",
        altText: "CCTV Installation Image",
      },
      {
        service: "electrical-safety-certification",
        imageSrc:
          "images/icons/electrical-services/electrical-safety-certification.png",
        imageSrcMobile:
          "images/icons/electrical-services/electrical-safety-certification-1.png",
        altText: "Electrical Safety Certification Image",
      },
      {
        service: "electrical-fixtures-and-fittings",
        imageSrc:
          "images/icons/electrical-services/electical-fixtures-and-fittings.png",
        imageSrcMobile:
          "images/icons/electrical-services/electical-fixtures-and-fittings-1.png",
        altText: "Electrical Fixtures and Fittings Image",
      },
      {
        service: "wiring-and-installs",
        imageSrc: "images/icons/electrical-services/wiring-and-installs.png",
        imageSrcMobile:
          "images/icons/electrical-services/wiring-and-installs-1.png",
        altText: "Wiring and Installs Image",
      },
      {
        service: "electrical-fault-finding",
        imageSrc:
          "images/icons/electrical-services/electical-fault-finding.png",
        imageSrcMobile:
          "images/icons/electrical-services/electical-fault-finding-1.png",
        altText: "Electrical Fault Finding Image",
      },
      {
        service: "fuse-board-and-consumer-unit-upgrade",
        imageSrc:
          "images/icons/electrical-services/fuse-board-and-consumer-unit-upgrade.png",
        imageSrcMobile:
          "images/icons/electrical-services/fuse-board-and-consumer-unit-upgrade-1.png",
        altText: "Fuse Board and Consumer Unit Upgrade Image",
      },
      {
        service: "something-else",
        imageSrc: "images/icons/something-else.png",
        imageSrcMobile: "images/icons/something-else-1.png",
        altText: "Something Else Image",
      },
    ],
  },
  {
    category: "plumbing-services",
    items: [
      {
        service: "bathroom-repairs-and-install",
        imageSrc:
          "images/icons/plumbing-services/bathroom-repairs-and-install.png",
        imageSrcMobile:
          "images/icons/plumbing-services/bathroom-repairs-and-install-1.png",
        altText: "Bathroom Repairs and Install Image",
      },
      {
        service: "boiler-repairs-and-install",
        imageSrc:
          "images/icons/plumbing-services/boiler-repairs-and-install.png",
        imageSrcMobile:
          "images/icons/plumbing-services/boiler-repairs-and-install-1.png",
        altText: "Boiler Repairs and Install Image",
      },
      {
        service: "install-and-repairs",
        imageSrc: "images/icons/plumbing-services/install-and-repairs.png",
        imageSrcMobile:
          "images/icons/plumbing-services/install-and-repairs-1.png",
        altText: "Repairs and Install Image",
      },
      {
        service: "gas-safety-certification",
        imageSrc: "images/icons/plumbing-services/gas-safety-certification.png",
        imageSrcMobile:
          "images/icons/plumbing-services/gas-safety-certification-1.png",
        altText: "Gas Safety Certification Image",
      },
      {
        service: "fixtures-and-fittings",
        imageSrc: "images/icons/plumbing-services/fixtures-and-fittings.png",
        imageSrcMobile:
          "images/icons/plumbing-services/fixtures-and-fittings-1.png",
        altText: "Fixtures and Fittings Image",
      },
      {
        service: "gas-safety-checks",
        imageSrc: "images/icons/plumbing-services/gas-safetly-checks.png",
        imageSrcMobile:
          "images/icons/plumbing-services/gas-safetly-checks-1.png",
        altText: "Gas Safety Checks Image",
      },
      {
        service: "something-else",
        imageSrc: "images/icons/something-else.png",
        imageSrcMobile: "images/icons/something-else-1.png",
        altText: "Something Else Image",
      },
    ],
  },
  {
    category: "painting-and-dec",
    items: [
      {
        service: "external-painting",
        imageSrc: "images/icons/painting-and-dec/external-painting.png",
        imageSrcMobile: "images/icons/painting-and-dec/external-painting-1.png",
        altText: "External Painting Image",
      },
      {
        service: "internal-painting",
        imageSrc: "images/icons/painting-and-dec/internal-painting.png",
        imageSrcMobile: "images/icons/painting-and-dec/internal-painting-1.png",
        altText: "Internal Painting Image",
      },
      {
        service: "woodwork-painting",
        imageSrc: "images/icons/painting-and-dec/woodwork-painting.png",
        imageSrcMobile: "images/icons/painting-and-dec/woodwork-painting-1.png",
        altText: "Woodwork Painting Image",
      },
      {
        service: "interior-decorating",
        imageSrc: "images/icons/painting-and-dec/interior-decorating.png",
        imageSrcMobile:
          "images/icons/painting-and-dec/interior-decorating-1.png",
        altText: "Interior Decorating Image",
      },
      {
        service: "wallpapering",
        imageSrc: "images/icons/painting-and-dec/wallpapering.png",
        imageSrcMobile: "images/icons/painting-and-dec/wallpapering-1.png",
        altText: "Wallpapering Image",
      },
      {
        service: "facade-and-upcv-spraying",
        imageSrc: "images/icons/painting-and-dec/facade-and-upcv-spraying.png",
        imageSrcMobile:
          "images/icons/painting-and-dec/facade-and-upcv-spraying-1.png",
        altText: "Facade and UPCV Image",
      },
      {
        service: "something-else",
        imageSrc: "images/icons/something-else.png",
        imageSrcMobile: "images/icons/something-else-1.png",
        altText: "Something Else Image",
      },
    ],
  },
  {
    category: "cleaning-services",
    items: [
      {
        service: "airbnb-and-after-party-cleaning",
        imageSrc:
          "images/icons/cleaning-services/airbnb-and-after-party-cleaning.png",
        imageSrcMobile:
          "images/icons/cleaning-services/airbnb-and-after-party-cleaning-1.png",
        altText: "Airbnb and After Party Cleaning Image",
      },
      {
        service: "carpet-cleaning",
        imageSrc: "images/icons/cleaning-services/carpet-cleaning.png",
        imageSrcMobile: "images/icons/cleaning-services/carpet-cleaning-1.png",
        altText: "Carpet Cleaning Image",
      },
      {
        service: "upholstery-cleaning",
        imageSrc: "images/icons/cleaning-services/upholstery-cleaning.png",
        imageSrcMobile:
          "images/icons/cleaning-services/upholstery-cleaning-1.png",
        altText: "Upholstery Cleaning Image",
      },
      {
        service: "outdoor-cleaning",
        imageSrc: "images/icons/cleaning-services/outdoor-cleaning.png",
        imageSrcMobile: "images/icons/cleaning-services/outdoor-cleaning-1.png",
        altText: "Outdoor Cleaning Image",
      },
      {
        service: "general-cleaning-home-and-office",
        imageSrc:
          "images/icons/cleaning-services/general-cleaninig-home-and-office.png",
        imageSrcMobile:
          "images/icons/cleaning-services/general-cleaninig-home-and-office-1.png",
        altText: "General Cleaning Home and Office Image",
      },
      {
        service: "home-help",
        imageSrc: "images/icons/cleaning-services/home-help.png",
        imageSrcMobile: "images/icons/cleaning-services/home-help-1.png",
        altText: "Home Help Image",
      },
      {
        service: "steam-cleaning",
        imageSrc: "images/icons/cleaning-services/steam-cleaning.png",
        imageSrcMobile: "images/icons/cleaning-services/steam-cleaning-1.png",
        altText: "Steam Cleaning Image",
      },
      {
        service: "window-cleaning",
        imageSrc: "images/icons/cleaning-services/window-cleaning.png",
        imageSrcMobile: "images/icons/cleaning-services/window-cleaning-1.png",
        altText: "Window Cleaning Image",
      },
      {
        service: "something-else",
        imageSrc: "images/icons/something-else.png",
        imageSrcMobile: "images/icons/something-else-1.png",
        altText: "Something Else Image",
      },
    ],
  },
  {
    category: "rubish-removal",
    items: [
      {
        service: "site-clearance",
        imageSrc: "images/icons/rubish-removal/site-clearance.png",
        imageSrcMobile: "images/icons/rubish-removal/site-clearance-1.png",
        altText: "Site Clearance Image",
      },
      {
        service: "waste-clearance",
        imageSrc: "images/icons/rubish-removal/waste-clearance.png",
        imageSrcMobile: "images/icons/rubish-removal/waste-clearance-1.png",
        altText: "Waste Clearance Image",
      },
      {
        service: "office-clear-out",
        imageSrc: "images/icons/rubish-removal/office-clear-out.png",
        imageSrcMobile: "images/icons/rubish-removal/office-clear-out-1.png",
        altText: "Office Clearout Image",
      },
      {
        service: "home-clear-out",
        imageSrc: "images/icons/rubish-removal/home-clear-out.png",
        imageSrcMobile: "images/icons/rubish-removal/home-clear-out-1.png",
        altText: "Home Clearout Image",
      },
      {
        service: "garden-waste-clearance",
        imageSrc: "images/icons/rubish-removal/garden-clearance.png",
        imageSrcMobile: "images/icons/rubish-removal/garden-clearance-1.png",
        altText: "Garden Clearance",
      },
      {
        service: "garage-and-shed-clearance",
        imageSrc: "images/icons/rubish-removal/garage-and-shed-clearance.png",
        imageSrcMobile:
          "images/icons/rubish-removal/garage-and-shed-clearance-1.png",
        altText: "Garage and Shed Clearance Image",
      },
      {
        service: "something-else",
        imageSrc: "images/icons/something-else.png",
        imageSrcMobile: "images/icons/something-else-1.png",
        altText: "Something Else Image",
      },
    ],
  },
  {
    category: "gardening",
    items: [
      {
        service: "gardening",
        imageSrc: "images/icons/gardening-services/gardening.png",
        imageSrcMobile: "images/icons/gardening-services/gardening-1.png",
        altText: "Gardening Image",
      },
      {
        service: "lawn-care",
        imageSrc: "images/icons/gardening-services/lawn-care.png",
        imageSrcMobile: "images/icons/gardening-services/lawn-care-1.png",
        altText: "Lawn Care Image",
      },
      {
        service: "land-scaping",
        imageSrc: "images/icons/gardening-services/land-scaping.png",
        imageSrcMobile: "images/icons/gardening-services/land-scaping-1.png",
        altText: "Land Scaping Image",
      },
      {
        service: "branch-and-hedge-trimming",
        imageSrc:
          "images/icons/gardening-services/branch-and-hedge-triming.png",
        imageSrcMobile:
          "images/icons/gardening-services/branch-and-hedge-triming-1.png",
        altText: "Branch and Hedge Trimming Image",
      },
      {
        service: "planting",
        imageSrc: "images/icons/gardening-services/planting.png",
        imageSrcMobile: "images/icons/gardening-services/planting-1.png",
        altText: "Planting Image",
      },
      {
        service: "fencing",
        imageSrc: "images/icons/gardening-services/fencing.png",
        imageSrcMobile: "images/icons/gardening-services/fencing-1.png",
        altText: "Fencing Image",
      },
      {
        service: "decking",
        imageSrc: "images/icons/gardening-services/decking.png",
        imageSrcMobile: "images/icons/gardening-services/decking-1.png",
        altText: "Decking Image",
      },
      {
        service: "something-else",
        imageSrc: "images/icons/something-else.png",
        imageSrcMobile: "images/icons/something-else-1.png",
        altText: "Something Else Image",
      },
    ],
  },
  {
    category: "transportation-and-moving",
    items: [
      {
        service: "man-and-van",
        imageSrc:
          "images/icons/transportation-and-moving-services/man-and-van.png",
        imageSrcMobile:
          "images/icons/transportation-and-moving-services/man-and-van-1.png",
        altText: "Man and Van Image",
      },
      {
        service: "moving-help",
        imageSrc:
          "images/icons/transportation-and-moving-services/moving-help.png",
        imageSrcMobile:
          "images/icons/transportation-and-moving-services/moving-help-1.png",
        altText: "Moving Help Image",
      },
      {
        service: "furniture-moving",
        imageSrc:
          "images/icons/transportation-and-moving-services/furniture-moving.png",
        imageSrcMobile:
          "images/icons/transportation-and-moving-services/furniture-moving-1.png",
        altText: "Furniture Moving Image",
      },
      {
        service: "store-collection",
        imageSrc:
          "images/icons/transportation-and-moving-services/store-collection.png",
        imageSrcMobile:
          "images/icons/transportation-and-moving-services/store-collection-1.png",
        altText: "Store Collection Image",
      },
      {
        service: "pickup-and-dropoff-heavy-goods",
        imageSrc:
          "images/icons/transportation-and-moving-services/pickup-and-dropoff-heavy-goods.png",
        imageSrcMobile:
          "images/icons/transportation-and-moving-services/pickup-and-dropoff-heavy-goods-1.png",
        altText: "Pickup and Dropoff Heavy Goods Image",
      },
      {
        service: "packing-and-moving",
        imageSrc:
          "images/icons/transportation-and-moving-services/packing-and-moving.png",
        imageSrcMobile:
          "images/icons/transportation-and-moving-services/packing-and-moving-1.png",
        altText: "Packing and Moving Image",
      },
      {
        service: "something-else",
        imageSrc: "images/icons/something-else.png",
        imageSrcMobile: "images/icons/something-else-1.png",
        altText: "Something Else Image",
      },
    ],
  },
];

export const handymanTables = [
  {
    region: "Greater London",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£55", "£70"],
          ["£60", "£75"],
        ],
        rates: "£85",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£32.50", "£40"],
          ["£35", "£42.50"],
        ],
        rates: "£47.50",
      },
    ],
  },
  {
    region: "Rest of the Uk",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£40", "£55"],
          ["£45", "£60"],
        ],
        rates: "£70",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£22.50", "£30"],
          ["£25", "£32.50"],
        ],
        rates: "£37.50",
      },
    ],
  },
];

export const electricianTables = [
  {
    region: "Greater London",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£80", "£95"],
          ["£85", "£100"],
        ],
        rates: "£110",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£45", "£52.50"],
          ["£47.50", "£55"],
        ],
        rates: "£60",
      },
    ],
  },
  {
    region: "Rest of the Uk",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£65", "£80"],
          ["£70", "£85"],
        ],
        rates: "£95",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£35", "£42.50"],
          ["£37.50", "£45"],
        ],
        rates: "£50",
      },
    ],
  },
];

export const plumberTables = [
  {
    region: "Greater London",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£70", "£85"],
          ["£75", "£90"],
        ],
        rates: "£100",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£40", "£47.50"],
          ["£42.50", "£50"],
        ],
        rates: "£55",
      },
    ],
  },
  {
    region: "Rest of the Uk",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£55", "£70"],
          ["£60", "£75"],
        ],
        rates: "£80",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£30", "£37.50"],
          ["£32.50", "£40"],
        ],
        rates: "£42.50",
      },
    ],
  },
];

export const painterTables = [
  {
    region: "Greater London",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£40", "£55"],
          ["£45", "£60"],
        ],
        rates: "£75",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£25", "£32.50"],
          ["£27.50", "£35"],
        ],
        rates: "£42.50",
      },
    ],
  },
  {
    region: "Rest of the Uk",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£25", "£40"],
          ["£30", "£45"],
        ],
        rates: "£55",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£15", "£22.50"],
          ["£17.50", "£25"],
        ],
        rates: "£30",
      },
    ],
  },
];

export const cleanerTables = [
  {
    region: "Greater London",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£40", "£55"],
          ["£45", "£60"],
        ],
        rates: "£75",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£25", "£32.50"],
          ["£27.50", "£35"],
        ],
        rates: "£42.50",
      },
    ],
  },
  {
    region: "Rest of the Uk",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£25", "£40"],
          ["£30", "£45"],
        ],
        rates: "£55",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£15", "£22.50"],
          ["£17.50", "£25"],
        ],
        rates: "£30",
      },
    ],
  },
];

export const wasteRemovalTables = [
  {
    region: "Greater London",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£60", "£75"],
          ["£65", "£80"],
        ],
        rates: "£95",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£35", "£42.50"],
          ["£37.50", "£45"],
        ],
        rates: "£52.50",
      },
    ],
  },
  {
    region: "Rest of the Uk",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£45", "£60"],
          ["£50", "£65"],
        ],
        rates: "£75",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£25", "£32.50"],
          ["£27.50", "£35"],
        ],
        rates: "£40",
      },
    ],
  },
];

export const gardenerTables = [
  {
    region: "Greater London",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£40", "£55"],
          ["£45", "£60"],
        ],
        rates: "£75",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£25", "£32.50"],
          ["£27.50", "£35"],
        ],
        rates: "£42.50",
      },
    ],
  },
  {
    region: "Rest of the UK",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£25", "£40"],
          ["£30", "£45"],
        ],
        rates: "£55",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£15", "£22.50"],
          ["£17.50", "£25"],
        ],
        rates: "£30",
      },
    ],
  },
];

export const ManWithAVanTables = [
  {
    region: "Greater London",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£50", "£65"],
          ["£55", "£70"],
        ],
        rates: "£85",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£30", "£37.50"],
          ["£32.50", "£40"],
        ],
        rates: "£47.50",
      },
    ],
  },
  {
    region: "Rest of the UK",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£35", "£50"],
          ["£40", "£55"],
        ],
        rates: "£65",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£20", "£27.50"],
          ["£22.50", "£30"],
        ],
        rates: "£35",
      },
    ],
  },
];
