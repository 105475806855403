import React, { useState } from "react";
import { loginbg } from "../../assets/images/base64/loginbg";
import logo from "../../assets/images/logo/logo.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import { useFormik } from "formik";
import * as yup from "yup";
import { resetPasswordApi } from "../../services/ApiServices";
import toast from "react-hot-toast";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  var emailId = "";
  try {
    const { email_id } = location.state;
    emailId = email_id;
  } catch (e) {
    //id not found
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+=-{};:"<>,./?]).*$/,
        "Password must contain at least one letter, one number, and one special character"
      ),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const params = {
        emailId: emailId,
        newpass: values.password,
      };

      resetPasswordApi(params)
        .then((res) => {
          const message = res.data.message;

          navigate("/signin", {
            state: {
              email_id: values.emailId,
            },
          });
          toast.success(message || "Otp sended Successfully!!!");
        })
        .catch((err) => {
          const message = err.response.data.message;
          console.log("err >>>", err);
          toast.error(message || "Somthing went wrong!!");
        });
    },
  });

  return (
    <div>
      <div className="grid grid-cols-12">
        <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 w-full 2xl:block xl:block lg:block hidden">
          <img
            src={loginbg}
            className="object-cover w-full h-screen"
            alt=""
          ></img>
        </div>
        <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 flex justify-center items-center">
          <div className="h-screen justify-center items-center flex">
            <div className="2xl:my-10 xl:my-10 lg:my-8 md:my-6 mx-5 min-w-72 w-auto">
              <div className="2xl:py-10 xl:py-10 lg:py-8 md:py-8 py-8 mx-5 md:min-w-80 min-w-72 w-auto">
                <div className="flex justify-center 2xl:py-10 py-5 2xl:hidden xl:hidden lg:hidden block">
                  <img src={logo} className="md:w-44 w-40" alt="" />
                </div>
                <div className="pt-4 mb-2">
                  <h1 className="2xl:text-3xl xl:text-2xl text-xl font-medium">
                    Reset Password
                  </h1>
                </div>
                <div className="my-4">
                  <p className="text-sm font-normal text-[#7E7E7E]">
                    Your new password must be unique from those
                    <br />
                    previously used.
                  </p>
                </div>
                <div className="py-4">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="mt-3">
                      <label
                        className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                        htmlFor="regular-form-2"
                      >
                        Password
                      </label>
                      <div className="relative">
                        <input
                          id="regular-form-2"
                          name="password"
                          placeholder="Enter Password"
                          type={showPassword ? "text" : "password"}
                          className="focus:outline-none py-3 ps-4 pe-11 block min-w-full xl:min-w-[350px] w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#37489B] sm:text-sm sm:leading-6"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                        />
                        <div className="" onClick={handleShowPassword}>
                          {showPassword ? (
                            <FaEye className="absolute text-gray-900 h-5 w-5 top-4 end-4 cursor-pointer" />
                          ) : (
                            <FaEyeSlash className="absolute text-gray-900 h-5 w-5 top-4 end-4 cursor-pointer" />
                          )}
                        </div>
                        {formik.touched.password && formik.errors.password ? (
                          <div className="text-danger my-1 text-sm">
                            {formik.errors.password}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="my-6 text-center">
                      <Button
                        className="w-full text-sm py-3 rounded-lg capitalize font-medium bg-primary"
                        type="submit"
                      >
                        Reset{" "}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
