import axios from "axios";
import {
  ACCOUNT_SETUP_API,
  FORGET_PASSWORD_API,
  LOGIN_API,
  RESET_PASSWORD_API,
  SEND_OTP_API,
  SIGNUP_API,
  VERIFICATION_FOR_EMAIL_API,
  LIST_MAIN_SERVICE_API,
  LIST_SUB_SERVICE_API,
  LIST_SERVICES_API,
  ADD_BOOKING_SERVICE_API,
  LIST_BOOKING_SERVICE_API,
  GET_SERVICE_DETAILS_API,
  ADD_REVIEW_API,
  GET_PROFILE_API,
  EDIT_PROFILE_API,
  LOGOUT_API,
  CHANGE_PASSWORD_API,
  CONTACT_US_API,
  DELETE_ACCOUNT_API,
  CANCEL_BOOKING_API,
  LIST_FAVORITE_BOOKING_API,
  ADD_TO_FAVORITE_API,
  LIST_REVIEW_API,
  CREATE_PAYMENT_INTENT_API,
  LIST_COUNTRY_API,
  LIST_CITY_API,
  LIST_NOTIFICATION_API,
  LIST_AREA_API,
  ADD_VAN_RENTAL_API,
  LIST_ALL_CITY_API,
  GET_PRICE_API,
  GET_ADMIN_CHARGE_API,
  GET_PRICEING_API,
  PAYMENT_SUCCESS_API,
} from "./Api";

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("user_token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const headersApplications = {
  "Content-Type": "application/json",
};

export const loginApi = (params) => {
  return axios.post(LOGIN_API, params, {
    headers: headersApplications,
  });
};

export const logoutApi = () => {
  return axios.post(LOGOUT_API);
};

export const signUpApi = (params) => {
  return axios.post(SIGNUP_API, params);
};

//verification & otp
export const verificationEmailApi = (params) => {
  return axios.post(VERIFICATION_FOR_EMAIL_API, params, {
    headers: headersApplications,
  });
};

export const sendOtpApi = (params) => {
  return axios.post(SEND_OTP_API, params, {
    headers: headersApplications,
  });
};

export const accountSetupApi = (params, user_token) => {
  return axios.post(ACCOUNT_SETUP_API, params, {
    headers: {
      Authorization: `Bearer ${user_token}`,
    },
  });
};

//forget password
export const forgetPasswordApi = (params) => {
  return axios.post(FORGET_PASSWORD_API, params, {
    headers: headersApplications,
  });
};

export const resetPasswordApi = (params) => {
  return axios.post(RESET_PASSWORD_API, params, {
    headers: headersApplications,
  });
};

export const getUserProfileApi = (params) => {
  return axios.get(GET_PROFILE_API, params, {
    headers: headersApplications,
  });
};

export const changePasswordApi = (params) => {
  return axios.post(CHANGE_PASSWORD_API, params, {
    headers: headersApplications,
  });
};

export const contactUsApi = (params) => {
  return axios.post(CONTACT_US_API, params, {
    headers: headersApplications,
  });
};

//service api
export const listServicesApi = (params) => {
  return axios.get(`${LIST_SERVICES_API}?pageNo=${params.pageNo}`, {
    headers: headersApplications,
  });
};

export const listMainServiceApi = () => {
  return axios.get(LIST_MAIN_SERVICE_API, {
    headers: headersApplications,
  });
};

export const listSubServiceApi = (params) => {
  return axios.get(`${LIST_SUB_SERVICE_API}?parentId=${params.parentId}`, {
    headers: headersApplications,
  });
};

//list review api
export const listReviewApi = () => {
  return axios.get(`${LIST_REVIEW_API}`, {
    headers: headersApplications,
  });
};

//booking service
export const addBookingServiceApi = (params) => {
  return axios.post(`${ADD_BOOKING_SERVICE_API}`, params);
};

export const getPriceApi = (serviceId, cityId, date, time) => {
  return axios.get(
    `${GET_PRICE_API}?serviceId=${serviceId}&cityId=${cityId}&date=${date}&time=${time}`
  );
};

export const listBookingServiceApi = (params) => {
  return axios.get(
    `${LIST_BOOKING_SERVICE_API}?pageNo=${params.pageNo}&bookingStatus=${params.bookingStatus}`,
    params
  );
};

//booking service details
export const getServiceDetailsApi = (params) => {
  return axios.get(`${GET_SERVICE_DETAILS_API}?bookingId=${params}`, {
    headers: headersApplications,
  });
};

//cancel booking
export const cancelServiceApi = (params) => {
  return axios.put(CANCEL_BOOKING_API, params, {
    headers: headersApplications,
  });
};

//add review
export const addBookingReviewApi = (params) => {
  return axios.post(`${ADD_REVIEW_API}`, params, {
    headers: headersApplications,
  });
};

//favourite
export const addFavouriteBookingApi = (params) => {
  return axios.post(ADD_TO_FAVORITE_API, params);
};

//profile
export const getProfileApi = () => {
  return axios.get(`${GET_PROFILE_API}`, {
    headers: headersApplications,
  });
};

export const editProfileApi = (params) => {
  return axios.put(`${EDIT_PROFILE_API}`, params, {
    headers: headersApplications,
  });
};

export const listFavouriteBookingApi = (params) => {
  return axios.get(`${LIST_FAVORITE_BOOKING_API}?pageNo=${params.pageNo}`);
};

export const getPricingApi = (params) => {
  return axios.get(`${GET_PRICEING_API}?bookingId=${params}`);
};

export const paymentSuccessApi = (params) => {
  return axios.post(`${PAYMENT_SUCCESS_API}`, params);
};

//delete account
export const deleteAccountApi = (params) => {
  return axios.delete(DELETE_ACCOUNT_API, params);
};

//payment
export const createPaymentIntentApi = (params) => {
  return axios.post(`${CREATE_PAYMENT_INTENT_API}`, params);
};

//list country, city & aera
export const listCountryApi = () => {
  return axios.get(LIST_COUNTRY_API, {
    headers: headersApplications,
  });
};

export const listCityApi = (params) => {
  return axios.get(`${LIST_CITY_API}?countryId=${params.countryId}`, {
    headers: headersApplications,
  });
};

export const listAllCityApi = () => {
  return axios.get(`${LIST_ALL_CITY_API}`, {
    headers: headersApplications,
  });
};

export const listAreaApi = (params) => {
  return axios.get(`${LIST_AREA_API}?cityId=${params.cityId}`, {
    headers: headersApplications,
  });
};

//notification
export const getNotificationListApi = (params) => {
  return axios.get(`${LIST_NOTIFICATION_API}?pageNo=${params.pageNo}`, {
    headers: headersApplications,
  });
};

//van_rental
export const addVanRentalApi = (params) => {
  return axios.post(`${ADD_VAN_RENTAL_API}`, params);
};

//get charge api
export const getAdminChargeApi = () => {
  return axios.get(`${GET_ADMIN_CHARGE_API}`);
};
