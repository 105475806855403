import { IMAGE_URL } from "../../services/Api";
import he from "he";

const ServiceSection = ({ data, activeTab }) => {
  return (
    <section
      className={`on-demand-services-section ${
        data.serviceId
      }-on-demand-services-section ${
        activeTab == data.serviceId ? "block" : "hidden"
      }`}
      id={`${data.serviceId}-on-demand-services-section`}
    >
      <div className="main-container">
        <div className="grid grid-cols-12 gap-2">
          <div className="col-span-12 2xl:col-span-7 xl:col-span-7 lg:col-span-7 md:col-span-6">
            <div className="image-container">
              <img
                src={IMAGE_URL + data?.serviceImage}
                alt={data?.serviceName}
                crossOrigin="anonymous"
                className="2xl:w-[700px] 2xl:h-[400px] xl:w-[700px] xl:h-[400px] lg:w-[700px] lg:h-[400px] w-[300px] h-[300px] object-cover obhect-center"
              />
              <div className="text">
                <h4 className="title">
                  {data?.serviceName ? he.decode(data?.serviceName) : ""}
                </h4>
                <p className="desc mb-2">
                  {data?.serviceDescription
                    ? he.decode(he.decode(data?.serviceDescription))
                    : "-"}
                </p>
              </div>
            </div>
          </div>
          <div className="col-span-12 2xl:col-span-5 xl:col-span-5 lg:col-span-5 md:col-span-6 content-container 2xl:px-0 xl:px-0 lg:px-0 md:px-0 px-5">
            <h1 className="title">
              Go Fix It Now, Your <span>On-Demand</span> Same day Home Repair
              Service
            </h1>
            <p className="desc">
              Go Fix It Now offers on-demand home repair services with a quick
              response, available 24/7 for various household issues from
              electrical and plumbing to furniture assembly and outdoor tasks.
              Customers can select services, outline requirements, and have a
              Fixer arrive within an hour for professional assistance in
              maintaining their home.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
