import React, { useEffect } from "react";
// import Header from "../../layouts/Header/Header";
import Footer from "../../layouts/Footer/Footer";
import Header from "../../components/home/Header";
import img1 from "../../assets/images/pages/howitwork/img1.png";
import img2 from "../../assets/images/pages/howitwork/img2.png";
import img3 from "../../assets/images/pages/howitwork/img3.png";
import img4 from "../../assets/images/pages/howitwork/img4.png";
import AOS from "aos";
import "aos/dist/aos.css";

const HowItWork = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Header />
      <main>
        <div className="2xl:mt-20 xl:mt-24 lg:mt-[68px] mt-[75px] mx-5">
          <section className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4">
            <div className="flex justify-center items-center text-center 2xl:h-[500px] xl:h-[500px] lg:h-[500px] md:h-[500px] h-[400px] w-full">
              <div className="w-[947px] mx-5">
                <div className="2xl:py-4">
                  <h1 className="text-info text-center 2xl:text-[60px] xl:text-[40px] lg:text-[36px] md:text-5xl text-4xl font-bold tracking-wider py-4">
                    How It{" "}
                    <b className="text-pending 2xl:text-[60px] xl:text-[40px] lg:text-[30px] md:text-5xl text-4xl font-bold tracking-wider">
                      Works?
                    </b>
                  </h1>
                </div>
                <div className="w-auto 2xl:py-8 xl:py-7 lg:py-6 md:py-5">
                  <h6 className="text-lg font-medium w-full leading-7 text-[#2F2F2F]">
                    Select a category then select the service you require if the
                    service you require is not there then select something else
                    from that service category and tell us what you need
                  </h6>
                </div>
              </div>
            </div>
          </section>
          <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4 pb-10">
            <div className="2xl:pb-6 xl:pb-6 pb-4">
              <div className="flex items-center justify-center 2xl:gap-6 xl:gap-5 gap-4 2xl:flex-nowrap xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap-reverse h-full">
                <div className="2xl:basis-2/4 xl:basis-2/4 lg:basis-2/4 md:basis-2/4">
                  <div className="">
                    <h2 className="text-start font-medium text-info 2xl:text-2xl xl:text-2xl lg:text-2xl text-xl tracking-wide 2xl:leading-9 xl:leading-9 lg:leading-9 leading-7">
                      Select the Category and the service you require
                    </h2>
                    <div className="my-2">
                      <p className="leading-7 text-[#414141] font-medium text-base">
                        Once you have selected the service you require let us
                        know when you require the service requested, enter the
                        description of the work along with any pictures or
                        videos, if there is any additional information that you
                        wish to share please add it with the description, and
                        submit
                      </p>
                    </div>
                  </div>
                </div>
                <div className="2xl:basis-2/4 xl:basis-2/4 lg:basis-2/4 md:basis-2/4">
                  <div className="flex justify-center items-center">
                    <img
                      src={img1}
                      className="object-fit object-center 2xl:w-[300px] 2xl:h-[300px] xl:w-[300px] 2xl:h-[300px] h-[280px] w-[280px]"
                      alt="card-img1"
                      //   data-aos="fade-up-left"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="2xl:py-6 xl:py-6 py-4">
              <div className="flex items-center justify-center 2xl:gap-6 xl:gap-5 gap-4 2xl:flex-nowrap xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap h-full">
                <div className="2xl:basis-2/4 xl:basis-2/4 lg:basis-2/4 md:basis-2/4">
                  <div className="flex justify-center items-center">
                    <img
                      src={img2}
                      className="object-fit object-center 2xl:w-[300px] 2xl:h-[300px] xl:w-[300px] 2xl:h-[300px] h-[280px] w-[280px]"
                      alt="card-img1"
                      //   data-aos="fade-up-right"
                    ></img>
                  </div>
                </div>
                <div className="2xl:basis-2/4 xl:basis-2/4 lg:basis-2/4 md:basis-2/4">
                  <div className="">
                    <h2 className="text-start font-medium text-info 2xl:text-2xl xl:text-2xl lg:text-2xl text-xl tracking-wide 2xl:leading-9 xl:leading-9 lg:leading-9 leading-7">
                      Our Local Fixers will accept your job
                    </h2>
                    <div className="my-2">
                      <p className="leading-7 text-[#414141] font-medium text-base">
                        As soon as we have all the details for your job, we'll
                        forward it on to relevant Fixers in your area. One of
                        our fixers will promptly accept the job and you will get
                        a confirmation that the job has been booked to your
                        desired time and date.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="2xl:py-6 xl:py-6 py-4">
              <div className="flex items-center justify-center 2xl:gap-6 xl:gap-5 gap-4 2xl:flex-nowrap xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap-reverse h-full">
                <div className="2xl:basis-2/4 xl:basis-2/4 lg:basis-2/4 md:basis-2/4">
                  <div className="">
                    <div className="my-2">
                      <p className="leading-7 text-[#414141] font-medium text-base">
                        Once there on there way you will be notified when our
                        fixers are on rout to you job you will get a
                        notification letting you know that our fixer is on the
                        way to you at that point you will be able to track and
                        communicate with our fixer
                      </p>
                    </div>
                  </div>
                </div>
                <div className="2xl:basis-2/4 xl:basis-2/4 lg:basis-2/4 md:basis-2/4">
                  <div className="flex justify-center items-center">
                    <img
                      src={img3}
                      className="object-fit object-center 2xl:w-[300px] 2xl:h-[300px] xl:w-[300px] 2xl:h-[300px] h-[280px] w-[280px]"
                      alt="card-img1"
                      //   data-aos="fade-up-left"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="2xl:py-6 xl:py-6 py-4">
              <div className="flex items-center justify-center 2xl:gap-6 xl:gap-5 gap-4 2xl:flex-nowrap xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap h-full">
                <div className="2xl:basis-2/4 xl:basis-2/4 lg:basis-2/4 md:basis-2/4">
                  <div className="flex justify-center items-center">
                    <img
                      src={img4}
                      className="object-fit object-center 2xl:w-[300px] 2xl:h-[300px] xl:w-[300px] 2xl:h-[300px] h-[280px] w-[280px]"
                      alt="card-img1"
                      //   data-aos="fade-up-right"
                    ></img>
                  </div>
                </div>
                <div className="2xl:basis-2/4 xl:basis-2/4 lg:basis-2/4 md:basis-2/4">
                  <div className="">
                    <h2 className="text-start font-medium text-info 2xl:text-2xl xl:text-2xl lg:text-2xl text-xl tracking-wide 2xl:leading-9 xl:leading-9 lg:leading-9 leading-7">
                      Leave feedback
                    </h2>
                    <div className="my-2">
                      <p className="leading-7 text-[#414141] font-medium text-base">
                        As soon as your job has been completed by the trade,
                        we'd love it if you could leave them a review. These
                        reviews are a great way to help our Fixers build their
                        online reputations and they're also really useful for
                        homeowners when adding them as favourite Fixers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default HowItWork;
